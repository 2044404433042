import { AppPageTypes } from "~/libs/constants";
import { appPageStore } from "~/libs/stores";
import { toast } from "~/libs/toast";
import BulkReceive from "~/pages/BulkReceive.svelte";
import List from "~/pages/List.svelte";
import Login from "~/pages/Login.svelte";
import Main from "~/pages/Main.svelte";
import Offline from "~/pages/Offline.svelte";
import NativeAppLog from "~/pages/OtherMenu/NativeAppLog.svelte";
import Notification from "~/pages/OtherMenu/Notification.svelte";
import PasswordChange from "~/pages/OtherMenu/PasswordChange.svelte";
import PresetOfDeliveryTimeFrame from "~/pages/OtherMenu/PresetOfDeliveryTimeFrame.svelte";
import OtherMenu from "~/pages/OtherMenu.svelte";
import OutForDeliveryWithQrCodeScan from "~/pages/OutForDeliveryWithQrCodeScan.svelte";
import PickupAndSortWithQrCodeScan from "~/pages/PickupAndSortWithQrCodeScan.svelte";
import QrHome from "~/pages/QrHome.svelte";
import ReturnToEcWithQrCodeScan from "~/pages/ReturnToEcWithQrCodeScan.svelte";
import TakebackPackageToDepotWithQrCodeScan from "~/pages/TakebackPackageToDepotWithQrCodeScan.svelte";
import Update from "~/pages/Update.svelte";

export default {
  /**
   * ログイン画面に遷移
   */
  moveToLogin() {
    movePage({
      type: AppPageTypes.BEFORE_LOGIN,
      name: Login.name,
    });
  },

  /**
   * メイン画面に遷移
   */
  moveToMain() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: Main.name,
    });
  },

  /**
   * 配達リスト画面に遷移
   */
  moveToList() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: List.name,
    });
  },

  /**
   * @param {import("~/libs/constants").QrHomeType} qrHomeType
   */
  moveToQrHome(qrHomeType) {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: QrHome.name,
      props: {
        qrHomeType: qrHomeType,
      },
    });
  },

  moveToPickupAndSortWithQrCodeScan() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: PickupAndSortWithQrCodeScan.name,
    });
  },

  moveToOutForDeliveryWithQrCodeScan() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: OutForDeliveryWithQrCodeScan.name,
    });
  },

  /**
   * 持ち戻りQRコードスキャン画面に遷移
   */
  moveToTakebackPackageToDepotWithQrCodeScan() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: TakebackPackageToDepotWithQrCodeScan.name,
    });
  },

  moveToUpdate() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: Update.name,
    });
  },

  /**
   * その他メニュー画面に遷移
   */
  moveToOtherMenu() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: OtherMenu.name,
    });
  },

  /**
   * パスワード変更画面に遷移
   */
  moveToPasswordChange() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: PasswordChange.name,
    });
  },

  /**
   * お知らせ画面に遷移
   */
  moveToNotification() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: Notification.name,
    });
  },

  /**
   * 配達時間帯のプリセット設定画面に遷移
   */
  moveToPresetOfDeliveryTimeFrame() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: PresetOfDeliveryTimeFrame.name,
    });
  },

  /**
   * ネイティブアプリログ画面に遷移
   */
  moveToNativeAppLog() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: NativeAppLog.name,
    });
  },

  /**
   * オフラインモード画面に遷移
   * @param {import("~/libs/constants").OfflineModeType} offlineModeType オフラインモードタイプ
   */
  moveToOfflineMode(offlineModeType) {
    movePage({
      type: AppPageTypes.OFFLINE_MODE,
      name: Offline.name,
      props: {
        offlineModeType: offlineModeType,
      },
    });
  },

  async removeContent() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: undefined,
    });
  },

  /**
   * 幹線輸送ドライバー用の返品登録画面に遷移
   *  @param {import("~/libs/constants").STATUS_RETURNING | import("~/libs/constants").STATUS_RETURNED} selectReturnWork 返品作業種別
   */
  moveToReturnToEcWithQrCodeScan(selectReturnWork) {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: ReturnToEcWithQrCodeScan.name,
      props: {
        selectReturnWork: selectReturnWork,
      },
    });
  },

  /**
   * 一括荷受け画面に遷移
   */
  moveToBulkReceive() {
    movePage({
      type: AppPageTypes.AFTER_LOGIN,
      name: BulkReceive.name,
    });
  },
};

/**
 * ページを移動します。
 * @param {import("~/libs/commonTypes").AppPageStore} appPageStoreObj
 */
function movePage(appPageStoreObj) {
  // 画面遷移に伴って消去可能なフラグが立っているToastをクリア
  toast.popWithPageMove();

  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");
  appPageStore.set(appPageStoreObj);

  window.scrollTo(0, 0);
}
