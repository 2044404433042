<script>
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";

  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import {
    CONTEXT_KEY_USER,
    QrHomeTypes,
    TO_HELD_IN_DEPOT,
    TO_IN_TRANSIT,
    TO_OUT_FOR_DELIVERY,
    TO_RETURN_TO_EC,
  } from "~/libs/constants";
  import QrHomeOutForDelivery from "~/pages/QrHome/QrHomeOutForDelivery.svelte";
  import QrHomePickupAndUnload from "~/pages/QrHome/QrHomePickupAndUnload.svelte";
  import QrHomeReturnToEc from "~/pages/QrHome/QrHomeReturnToEc.svelte";
  import QrHomeTakebackPackageToDepot from "~/pages/QrHome/QrHomeTakebackPackageToDepot.svelte";

  /** QrHome画面の種別 @type {import("~/libs/constants").QrHomeTypes} */
  export let qrHomeType;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** ヘッダに表示する、QrHome画面の種別に応じたタイトルの連想配列 */
  const headerWorkTitles = {
    [QrHomeTypes.PICKUP_AND_SORT]: TO_IN_TRANSIT,
    [QrHomeTypes.OUT_FOR_DELIVERY]: TO_OUT_FOR_DELIVERY,
    [QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT]: TO_HELD_IN_DEPOT,
    [QrHomeTypes.RETURN_TO_EC]: TO_RETURN_TO_EC,
  };
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.loginUser.switchableRoles?.length}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center"
      >{headerWorkTitles[qrHomeType]}</svelte:fragment
    >
  </Header>

  <main in:fade>
    {#if qrHomeType === QrHomeTypes.PICKUP_AND_SORT}
      <QrHomePickupAndUnload />
    {:else if qrHomeType === QrHomeTypes.OUT_FOR_DELIVERY}
      <QrHomeOutForDelivery />
    {:else if qrHomeType === QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT}
      <QrHomeTakebackPackageToDepot />
    {:else if qrHomeType === QrHomeTypes.RETURN_TO_EC}
      <QrHomeReturnToEc />
    {/if}
  </main>

  <Footer />
</div>

<style lang="scss">
  /* ------------
  コンテンツ部分
 --------------- */
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :global(p.img) {
      text-align: center;
    }

    :global(p.img img) {
      width: calc(100% - 80px);
    }

    :global(.inputAreaWrapper) {
      width: calc(100% - 30px);
      margin: 20px 15px 0;
      background-color: #fff;
      border-radius: 10px;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    :global(.inputArea) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 15px;
    }

    :global(.inputGroup) {
      display: flex;
      flex-direction: column;
    }

    :global(.inputGroup .groupName) {
      border-bottom: solid 1px #b2b2b2;
      padding-bottom: 4px;
      font-size: 14px;
    }

    :global(.inputGroup .groupContent) {
      margin: 10px 0 0 8px;
    }
  }
</style>
