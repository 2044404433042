import { TZDate } from "@date-fns/tz";
import { format as formatDate, parse as parseDate } from "date-fns";

/**
 * 日付文字列を指定の書式でフォーマットする。
 * @param {string} date 日付文字列
 * @param {string} formatStr date-fnsのフォーマット書式
 * @param {import("date-fns").FormatOptions} [options] date-fnsのフォーマットオプション
 * @returns {string} フォーマット済の日付文字列
 */
export function formatStringDate(date, formatStr, options) {
  /** @type {Date} */
  let parsedDate;
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm:ss", new Date());
  } else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm", new Date());
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd", new Date());
  }
  return formatDate(parsedDate ?? date, formatStr, options);
}

/**
 * 日本標準時での現在時刻を取得する。
 * @returns {Date} 現在時刻（日本標準時）
 */
export function getCurrentDateTimeOnJst() {
  const currentUtc = new Date().toISOString();
  const dateObj = new TZDate(currentUtc, "Asia/Tokyo");
  return dateObj;
}
